import React, { useEffect, useState } from "react";
import PlayersTable from "../components/PlayersTable/PlayersTable";
import AdminPlayerModal from "../components/Modals/AdminModals/AdminPlayerModal";

import "./Admin.scss";
import { Player } from "../Models/Player";
import AdminInformationData from "../components/AdminInformationData/AdminInformationData";
import { Information } from "../Models/Information";
import Login from "./Login";
import { getAdminToken } from "../services/auth";

interface AdminPageProps {
  players: Player[] | undefined;
  informationData: Information | undefined;
  setPlayers: (player: Player[]) => void;
}

const AdminPage = (props: AdminPageProps) => {
  const { players, informationData, setPlayers } = props;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [adminPlayerModalOpen, setAdminPlayerModalOpen] = useState(false);
  const [playerToEdit, setPlayerToEdit] = useState<Player | undefined>(
    undefined
  );

  useEffect(() => {
    const token = getAdminToken();
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (!adminPlayerModalOpen) {
      setPlayerToEdit(undefined);
    }
  }, [adminPlayerModalOpen]);

  function addNewPayer(newPlayer: Player): void {
    setPlayers([...(players ?? []), newPlayer]);
  }

  function deletePlayer(playerId: string): void {
    if (!players) {
      return;
    }
    setPlayers(players.filter((player) => player.id !== playerId));
  }

  function renderAdminInformation() {
    if (!isAuthenticated) {
      return;
    }

    return (
      <>
        <AdminInformationData informationData={informationData} />
        <PlayersTable
          players={players}
          deletePlayer={deletePlayer}
          setAdminPlayerModalOpen={setAdminPlayerModalOpen}
          setPlayerToEdit={setPlayerToEdit}
        />
        <button onClick={() => setAdminPlayerModalOpen(true)}>הוסף נגן</button>
        {adminPlayerModalOpen && (
          <AdminPlayerModal
            closeModal={() => setAdminPlayerModalOpen(false)}
            addNewPayer={addNewPayer}
            player={playerToEdit}
          />
        )}
      </>
    );
  }

  return (
    <div className="admin-page-container">
      {!isAuthenticated && <Login setIsAuthenticated={setIsAuthenticated} />}
      {renderAdminInformation()}
    </div>
  );
};

export default AdminPage;
