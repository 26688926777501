type sourceCountsType = { [sourceName: string]: number };

export interface InformationJson {
  aboutUs: string;
  whatIsNext: string;
}

export interface Sources {
  [source: string]: number;
}

export interface InformationType {
  information: InformationJson;
  sources: Sources;
}

export class Information {
  about: string;
  whatIsNext: string;

  constructor(about: string, whatIsNext: string) {
    this.about = about;
    this.whatIsNext = whatIsNext;
  }

  setSourceCounts(source: string, count: number) {
    this.setSourceCounts[source] = count;
  }
}
