import { useState } from "react";

import "./PlayerCard.scss";
import PlayerModal from "../Modals/PlayerModal/PlayerModal";
import { Player } from "../../Models/Player";

interface PlayerCardProps {
  player: Player;
  scrollToContactUs: () => void;
}

function PlayerCard(props: PlayerCardProps) {
  const { player, scrollToContactUs } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const modalCloseClassStyle = !isModalOpen && "modal-close";

  function closeModal() {
    setIsModalOpen(false);
  }

  const renderPlayerModal = () => {
    return (
      <PlayerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        scrollToContactUs={scrollToContactUs}
        imgSrc={player.modalPicture}
        name={player.name}
        description={player.description}
      />
    );
  };

  return (
    <div
      className={`player-card-container ${modalCloseClassStyle}`}
      onClick={() => setIsModalOpen(true)}
    >
      {renderPlayerModal()}
      <img className="player-image" src={player.profilePicture} />
    </div>
  );
}

export default PlayerCard;
