interface PlayerJson {
  id: number;
  name: string;
  description: string;
  profilePicture?: any;
  modalPicture?: any;
  video?: any;
}

export class Player {
  id: string;
  name: string;
  description: string;
  profilePicture?: any;
  modalPicture?: any;
  video?: any;

  constructor(
    id: string,
    name: string,
    description: string,
    profilePicture?: any,
    modalPicture?: any,
    video?: any
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.profilePicture = profilePicture ?? "";
    this.modalPicture = modalPicture ?? "";
    this.video = video ?? "";
  }

  updateFromJson(player: any) {
    this.id = player.id;
    this.name = player.name;
    this.description = player.description;
    this.profilePicture = player.profilePicture;
    this.modalPicture = player.modalPicture;
    this.video = player.video;
  }
}
