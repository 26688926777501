import React, { useState } from "react";
import TextInput from "../Inputs/TextInput";
import Textarea from "../Inputs/TextArea";
import "./ContactForm.scss";
import { submitToMonday } from "../../services/Monday";

interface ContactFormProps {
  source: string;
}

export const ContactUs = (props: ContactFormProps) => {
  const { source } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [formSuccessfullySubmited, setFormSuccessfullySubmited] =
    useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const formData = {
      name,
      phone,
      email,
      date,
      description,
      source,
    };
    await submitToMonday(formData);
    clearFormValues();
    setFormSuccessfullySubmited(true);
  };

  const clearFormValues = () => {
    setName("");
    setPhone("");
    setEmail("");
    setDate("");
    setDescription("");
  };

  const renderFormSubmitedSuccessfully = () => {
    if (!formSuccessfullySubmited) {
      return null;
    }

    return (
      <div className="successfully-submited-text">
        הפרטים נשלחו בהצלחה! ניצור איתך קשר בהקדם
      </div>
    );
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form-header">נשמח לחגוג איתך!</div>
      {renderFormSubmitedSuccessfully()}
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <TextInput
            label="שם מלא"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            requiredMessage="פספסת את השם"
          />
          <TextInput
            label="טלפון"
            name="phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            requiredMessage="פספסת מספר טלפון"
          />
          <TextInput
            label="מייל"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            requiredMessage="פספסת את האימייל"
          />
          <TextInput
            label="תאריך אירוע"
            name="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <Textarea
            label="ספר לנו על האירוע"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="submit-button-container">
            <input type="submit" className="submit-button" value="שליחה" />
          </div>
        </form>
      </div>
    </div>
  );
};
