import React, { useState } from "react";
import { Information } from "../../Models/Information";
import "./AdminInformationData.scss";
import Textarea from "../Inputs/TextArea";
import { editinformationField } from "../../services/api";

interface AdminInformationDataProps {
  informationData: Information | undefined;
}

function AdminInformationData(props: AdminInformationDataProps) {
  const { informationData } = props;

  const [editAbout, setEditAbout] = useState(false);
  const [editWhatIsNext, setEditWhatIsNext] = useState(false);

  const [aboutValue, setAboutValue] = useState(informationData?.about);
  const [whatIsNextValue, setWhatIsNextValue] = useState(
    informationData?.whatIsNext
  );

  if (!informationData) {
    return null;
  }

  async function handleAboutSaved() {
    if (!aboutValue?.trim().length) {
      return;
    }
    await editinformationField(aboutValue!, "aboutUs");
    setEditAbout(false);
  }

  async function handleWhatIsNextSaved() {
    if (!whatIsNextValue?.trim().length) {
      return;
    }
    await editinformationField(whatIsNextValue!, "whatIsNext");
    setEditWhatIsNext(false);
  }

  function renderAbout() {
    if (editAbout) {
      return (
        <div className="info-field-item">
          <Textarea
            label="קצת עלינו"
            name="description"
            value={aboutValue}
            onChange={(e) => setAboutValue(e.target.value)}
          />
          <button className="edit-field-btn" onClick={handleAboutSaved}>
            שמור
          </button>
        </div>
      );
    }
    return (
      <div className="info-field-item">
        {aboutValue}
        <button className="edit-field-btn" onClick={() => setEditAbout(true)}>
          ערוך
        </button>
      </div>
    );
  }

  function renderWhatIsNext() {
    if (editWhatIsNext) {
      return (
        <div className="info-field-item">
          <Textarea
            label="איך זה עובד"
            name="what is next"
            value={whatIsNextValue}
            onChange={(e) => setWhatIsNextValue(e.target.value)}
          />
          <button className="edit-field-btn" onClick={handleWhatIsNextSaved}>
            שמור
          </button>
        </div>
      );
    }
    return (
      <div className="info-field-item">
        {whatIsNextValue}
        <button
          className="edit-field-btn"
          onClick={() => setEditWhatIsNext(true)}
        >
          ערוך
        </button>
      </div>
    );
  }

  return (
    <div className="admin-information-container">
      <div className="admin-information-fields-container">
        <h3>קצת עלינו</h3>
        {renderAbout()}
        <h3>איך זה עובד</h3>
        {renderWhatIsNext()}
      </div>
    </div>
  );
}

export default AdminInformationData;
