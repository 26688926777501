import React, { useEffect, useState } from "react";
import "./PlayerModal.scss";

interface PlayerModalProps {
  imgSrc: string;
  name: string;
  description: string;
  isOpen: boolean;
  scrollToContactUs: () => void;
  onClose: () => void;
}

const PlayerModal: React.FC<PlayerModalProps> = ({
  isOpen,
  scrollToContactUs,
  onClose,
  imgSrc,
  name,
  description,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((event.target as HTMLElement).className === "modal-overlay") {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  const formattedDescription = description?.replace(/\r\n|\r|\n/g, "<br />");

  const handleCloseModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  const handleContactMeClickInPlayerModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
    scrollToContactUs();
  };

  return (
    <div className="modal-overlay" onScroll={(e) => e.preventDefault()}>
      <div className="modal-content">
        <button className="close-button" onClick={handleCloseModal}>
          &times;
        </button>
        <div className="modal-body">
          <img src={imgSrc} alt={name} className="player-img" />
          <div className="modal-text">
            <h2>{name}</h2>
            <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
            <div className="contact-me-in-player-modal">
              <button
                className="submit-button"
                onClick={handleContactMeClickInPlayerModal}
              >
                צרו איתי קשר
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerModal;
