import { InformationJson } from "../Models/Information";
import { ADMIN_TOKEN } from "../consts/consts";
import { getAdminToken } from "./auth";
import players from "../players.json";
import information from "../information.json";

export async function login(password: string) {
  try {
    const res = await fetch("/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password }),
    });
    if (!res.ok) {
      throw new Error("Login failed");
    }
    const data = await res.json();
    return data.token;
  } catch (error) {
    console.error("Error during login:", error);
    return;
  }
}

export function getInformation(): InformationJson {
  return information;
}

export async function editinformationField(value: string, path: string) {
  try {
    const token = getAdminToken();
    const res = await fetch(`information/${path}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ [path]: value }),
    });

    deleteTokenIfNeeded(res);

    if (!res.ok) {
      throw new Error(`Failed to update ${path}`);
    }
    return await res.json();
  } catch (error) {
    console.error(`Error updating ${path}:`, error);
    return null;
  }
}

export function getPlayers() {
  return players;
}

export async function createPlayerApi(player) {
  try {
    const token = getAdminToken();
    const formData = new FormData();
    formData.append("name", player.name);
    formData.append("description", player.description);
    formData.append("profilePicture", player.profilePicture);
    formData.append("modalPicture", player.modalPicture);

    const res = await fetch("/players", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    deleteTokenIfNeeded(res);

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error creating player:", error);
    return null;
  }
}

export async function deletePlayerApi(playerId: string) {
  try {
    const token = getAdminToken();
    const res = await fetch(`/players/${playerId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    deleteTokenIfNeeded(res);

    if (!res.ok) {
      throw new Error("Failed to delete the player");
    }
    return await res.json();
  } catch (error) {
    console.error("Error deleting player:", error);
    return null;
  }
}

export async function editPlayerApi(id: string, updatedPlayer: any) {
  try {
    const token = getAdminToken();

    const formData = new FormData();
    formData.append("name", updatedPlayer.name);
    formData.append("description", updatedPlayer.description);
    if (updatedPlayer.profilePicture) {
      formData.append("profilePicture", updatedPlayer.profilePicture);
    }
    if (updatedPlayer.modalPicture) {
      formData.append("modalPicture", updatedPlayer.modalPicture);
    }

    const res = await fetch(`/players/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    deleteTokenIfNeeded(res);

    if (!res.ok) {
      throw new Error("Failed to update the player");
    }

    return await res.json();
  } catch (error) {
    console.error("Error updating player:", error);
    return null;
  }
}

/* Bi functions */

export async function updateSourceCounter(source: string) {
  if (!source || source?.trim()?.length === 0) {
    return;
  }

  try {
    const res = await fetch("/source", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ source }),
    });
    if (!res.ok) {
      throw new Error("Failed to update source counter");
    }
    return await res.json();
  } catch (error) {
    console.error("Error updating source counter:", error);
    return null;
  }
}

export async function getSourceCounts() {
  try {
    const res = await fetch("/source");
    if (!res.ok) {
      throw new Error("Failed to fetch source counts");
    }
    return await res.json();
  } catch (error) {
    console.error("Error fetching source counts:", error);
    return null;
  }
}

function deleteTokenIfNeeded(res) {
  if (res.status === 403 || res.status === 401) {
    localStorage.removeItem(ADMIN_TOKEN);
    window.location.reload(); // Refresh the page
  }
}
