interface MondayForm {
  name: string;
  phone: string;
  email: string;
  date: string;
  description: string;
  source: string;
}

const sourceMap = {
  rani: 11,
  raniTikTok: 0,
  raniYoutube: 3,
  raniInstagram: 7,
  royTikTok: 8,
  royInstagram: 9,
  roy: 10,
  tamarTikTok: 12,
  tamarInstagram: 13,
  tamar: 14,
  ofirTikTok: 15,
  ofirInstagram: 16,
  ofir: 17,
  playersInstagram: 18,
  playersTikTok: 19,
  other: 101,
};

const API_TOKEN = process.env.REACT_APP_MONDAY_API_TOKEN;

export const submitToMonday = async (formFields: MondayForm) => {
  const boardId = 5615757978;

  console.log("formFields", formFields);

  const formData = {
    name: formFields.name,
    email: formFields.email,
    text: formFields.email,
    phone: formFields.phone,
    countryShortName: "IL",
    date: formFields.date,
    long_text: formFields.description,
  };

  const jsonData = {
    email: {
      email: formData.email,
      text: formData.text,
    },
    phone: {
      phone: formData.phone,
      countryShortName: formData.countryShortName,
    },
    date: {
      date: formData.date,
    },
    long_text: formData.long_text,
  };

  if (formFields.source in sourceMap) {
    jsonData["single_select__1"] = String(sourceMap[formFields.source]);
  }

  const columnValues = JSON.stringify(jsonData);
  console.log("columnValues", columnValues);

  const query = `mutation createItem($myItemName: String!, $columnVals: JSON!) {
      create_item (
        board_id: ${boardId},
        item_name: $myItemName,
        column_values: $columnVals
      ) {
        id
      }
    }`;

  try {
    const response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { myItemName: formData.name, columnVals: columnValues },
      }),
    });

    const data = await response.json();
    if (response.ok) {
      console.log("Success:", data);
    } else {
      console.error("Error:", data);
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const saveSourceBIEvent = async (source: string) => {
  const boardId = 7528641272;

  const jsonData = {};

  if (source in sourceMap) {
    jsonData["status9__1"] = String(sourceMap[source]);
  }

  const columnValues = JSON.stringify(jsonData);
  console.log("columnValues", columnValues);

  const query = `mutation createItem($myItemName: String!, $columnVals: JSON!) {
      create_item (
        board_id: ${boardId},
        item_name: $myItemName,
        column_values: $columnVals
      ) {
        id
      }
    }`;

  try {
    const response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { myItemName: "כניסה לאתר", columnVals: columnValues },
      }),
    });

    const data = await response.json();
    if (response.ok) {
      console.log("Success:", data);
    } else {
      console.error("Error:", data);
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
};
