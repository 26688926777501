import React, { ChangeEvent, useRef } from "react";
import "./TextInput.scss";

interface TextInputProps {
  label: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  required?: boolean;
  type?: string;
  requiredMessage?: string;
}

const TextInput = ({
  label,
  value,
  onChange,
  name,
  requiredMessage = "",
  required = false,
  type = "text",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInvalid = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.setCustomValidity(requiredMessage);
  };

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    input.setCustomValidity("");
  };

  return (
    <div className="text-input">
      <label htmlFor={name}>{label}</label>
      <input
        ref={inputRef}
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onInvalid={handleInvalid}
        onInput={handleInput}
        required={required}
      />
    </div>
  );
};

export default TextInput;
