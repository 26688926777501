import { useEffect, useState } from "react";

export function useWindowWidth(): number {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function resizeEvent(ev) {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", resizeEvent);

    return () => window.removeEventListener("resize", resizeEvent);
  }, []);

  return width;
}
