import React from "react";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import "./PlayersTable.scss";
import { Player } from "../../Models/Player";
import { deletePlayerApi } from "../../services/api";

interface PlayersTableProps {
  players: Player[] | undefined;
  deletePlayer: (playerId: string) => void;
  setAdminPlayerModalOpen: (isOpen: boolean) => void;
  setPlayerToEdit: (player: Player) => void;
}

const PlayersTable = (props: PlayersTableProps) => {
  const { players, deletePlayer, setAdminPlayerModalOpen, setPlayerToEdit } =
    props;

  async function handleDeletePlayer(playerId: string) {
    const confirmDelete = window.confirm(
      "האם אתה בטוח שאתה רוצה למחוק את הנגן?"
    );
    if (!confirmDelete) {
      return;
    }

    const deletedPlayer = await deletePlayerApi(playerId);
    if (deletedPlayer) {
      deletePlayer(playerId);
    }
  }

  async function handleEditPlayer(playerId: string) {
    const playerToEdit = players?.find((player) => player.id === playerId);
    if (!playerToEdit) {
      return;
    }
    setPlayerToEdit(playerToEdit);
    setAdminPlayerModalOpen(true);
  }

  return (
    <div className="table-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>שם</th>
            <th className="expand">תיאור</th>
            <th>פעולות</th>
          </tr>
        </thead>

        <tbody>
          {players?.map((player: Player, idx: number) => {
            const key = player.id ? player.id : `index-${idx}`;
            return (
              <tr key={key}>
                <td>{player.name}</td>
                <td className="expand text-ellipsis">{player.description}</td>
                <td>
                  <span className="actions">
                    <BsFillTrashFill
                      className="delete-btn"
                      onClick={() => handleDeletePlayer(player.id)}
                    />
                    <BsFillPencilFill
                      onClick={() => handleEditPlayer(player.id)}
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PlayersTable;
