import { useState } from "react";
import { login } from "../services/api";
import { ADMIN_TOKEN } from "../consts/consts";

interface LoginProps {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const Login = (props: LoginProps) => {
  const { setIsAuthenticated } = props;

  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  async function handleLogin() {
    try {
      const token = await login(password);
      if (token) {
        const now = new Date().getTime();
        const expiry = now + 24 * 60 * 60 * 1000; // 24 hours in milliseconds

        const tokenObject = {
          value: token,
          expiry,
        };

        localStorage.setItem(ADMIN_TOKEN, JSON.stringify(tokenObject));
        setIsAuthenticated(true);
      } else {
        setMessage("לא טוב, פנה לגולן");
      }
    } catch (error) {
      setMessage("לא טוב, פנה לגולן");
    }
  }

  return (
    <div>
      <h2>קדימה</h2>
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>נו רני</button>
      <p>{message}</p>
    </div>
  );
};

export default Login;
