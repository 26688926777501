import React, { useState } from "react";
import { createPlayerApi, editPlayerApi } from "../../../services/api";
import "./AdminPlayerModal.scss";
import { Player } from "../../../Models/Player";

interface AdminPlayerModalProps {
  closeModal: () => void;
  addNewPayer: (player: Player) => void;
  player?: Player;
}

const AdminPlayerModal = (props: AdminPlayerModalProps) => {
  const { closeModal, addNewPayer, player } = props;
  const [name, setName] = useState(player?.name || "");
  const [description, setDescription] = useState(player?.description || "");
  const [profilePicture, setProfilePicture] = useState<File | undefined>(
    player?.profilePicture
  );
  const [modalPicture, setModalPicture] = useState<File | undefined>(
    player?.modalPicture
  );

  return (
    <div className="admin-modal-container" onClick={handleClickOutsideModal}>
      <div className="modal-container">{renderForm()}</div>
    </div>
  );

  function handleClickOutsideModal(e) {
    if (e.target.className === "admin-modal-container") {
      closeModal();
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (name.trim().length === 0) {
      return;
    }

    if (player) {
      const playerToEdit = {
        name,
        description,
        profilePicture,
        modalPicture,
      };

      await editPlayerApi(player.id, playerToEdit);
      player.updateFromJson(playerToEdit);
    } else {
      await handleCreateNewPlayer();
    }
    closeModal();
  }

  async function handleCreateNewPlayer() {
    const newPlayer = { name, description, profilePicture, modalPicture };
    const playerResult = await createPlayerApi(newPlayer);
    if (playerResult) {
      const newPlayerCreated = new Player(
        playerResult.id,
        playerResult.name,
        playerResult.description,
        playerResult.profilePicture ?? null,
        playerResult.modalPicture ?? null,
        playerResult.video ?? null
      );
      addNewPayer(newPlayerCreated);
    }
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">שם</label>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">תיאור</label>
          <textarea
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="profilePicture">תמונה</label>
          <input
            type="file"
            name="profilePicture"
            onChange={(e) => setProfilePicture(e?.target?.files?.[0])}
          />
        </div>
        <div className="form-group">
          <label htmlFor="modalPicture">תמונה מלאה</label>
          <input
            type="file"
            name="modalPicture"
            onChange={(e) => setModalPicture(e?.target?.files?.[0])}
          />
        </div>
        <button className="submit-btn" type="submit">
          שמור
        </button>
      </form>
    );
  }
};

export default AdminPlayerModal;
