import { ADMIN_TOKEN } from "../consts/consts";

export function getAdminToken() {
  const tokenStr = localStorage.getItem(ADMIN_TOKEN);

  if (!tokenStr) return null;

  const tokenObject = JSON.parse(tokenStr);
  const now = new Date().getTime();

  if (now > tokenObject.expiry) {
    // Token expired, remove it from localStorage
    localStorage.removeItem(ADMIN_TOKEN);
    return null;
  }

  return tokenObject.value;
}
