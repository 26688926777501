import React, { useEffect, useRef } from "react";
import "./ParallaxVideo.scss";
import { useWindowWidth } from "./hooks";
const logo = require("../../images/logo.png");

interface ParallaxVideoProps {
  scrollToContactUs: () => void;
}

export default function ParallaxVideo(props: ParallaxVideoProps) {
  const { scrollToContactUs } = props;

  const width = useWindowWidth();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const playVideo = () => {
      if (videoElement) {
        videoElement.muted = true;
        videoElement.playsInline = true;
        videoElement.play().catch((error) => {
          console.error("Error attempting to play", error);
        });
      }
    };

    // Attempt to play the video once the component mounts
    playVideo();

    // Add a touchstart/click listener to the document to play video
    document.addEventListener("touchstart", playVideo, { once: true });
    document.addEventListener("click", playVideo, { once: true });

    return () => {
      document.removeEventListener("touchstart", playVideo);
      document.removeEventListener("click", playVideo);
    };
  }, []);

  return (
    <div className="parallax-video-container">
      <div className="logo-and-contact-container">
        <img src={logo} className="logo" alt="Logo" />
        <button className="submit-button on-video" onClick={scrollToContactUs}>
          צרו איתי קשר
        </button>
      </div>
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        controlsList=""
        disablePictureInPicture
        style={{ width }}
      >
        <source src="assets/players-video.mp4" type="video/mp4" />
      </video>
    </div>
  );
}
