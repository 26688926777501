import React, { useEffect, useState } from "react";
import "./App.scss";
import MainPage from "./components/MainPage/MainPage";
import { Routes, Route } from "react-router-dom";
import Admin from "./pages/Admin";
import { getInformation, getPlayers } from "./services/api";
import { Player } from "./Models/Player";
import { Information, InformationJson } from "./Models/Information";

const App: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>();
  const [information, setInformation] = useState<Information | undefined>();
  const [isDataFetchLoading, setIsDataFetchLoading] = useState(true);

  useEffect(() => {
    initInformation(getInformation());
    initPlayersArray(getPlayers());
    setIsDataFetchLoading(false);
  }, []);

  if (isDataFetchLoading) {
    return null;
  }

  function initPlayersArray(playersData) {
    const playersArray = playersData?.map(
      (player) =>
        new Player(
          player.id,
          player.name,
          player.description,
          player.profilePicture,
          player.modalPicture,
          player.video
        )
    );
    setPlayers(playersArray || []);
  }

  function initInformation(informationJson: InformationJson) {
    const information = new Information(
      informationJson?.aboutUs,
      informationJson?.whatIsNext
    );
    setInformation(information);
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/adminRani"
          element={
            <Admin
              players={players}
              informationData={information}
              setPlayers={setPlayers}
            />
          }
        />
        <Route
          path="/"
          element={<MainPage players={players} informationData={information} />}
        />
      </Routes>
    </div>
  );
};

export default App;
