import React, { useEffect, useRef, useState } from "react";

import "./MainPage.scss";
import instagramIcon from "../../images/instagram-icon.png";
import PlayerCard from "../PlayerCard/PlayerCard";
import ParallaxVideo from "../ParallaxVideo/ParallaxVideo";
import { ContactUs } from "../ContactForm/ContactForm";
import { FaWhatsapp } from "react-icons/fa";
import { INSTAGRAM_PROFILE, PHONE_NUMBER } from "../../consts/consts";
import { Player } from "../../Models/Player";
import { useSearchParams } from "react-router-dom";
import { Information, InformationJson } from "../../Models/Information";
import { saveSourceBIEvent } from "../../services/Monday";

interface MainPageProps {
  players: Player[] | undefined;
  informationData: Information | undefined;
}

const MainPage = (props: MainPageProps) => {
  const { players, informationData } = props;
  const queryParams = useSearchParams()[0];
  const source = queryParams.get("source") || "other";

  const [animate, setAnimate] = useState(false);

  const contactUsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sendEventSource();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 500);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const scrollToContactUs = () => {
    contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      (document.querySelector("#name") as HTMLInputElement)?.focus();
    }, 750);
  };

  function sendEventSource() {
    saveSourceBIEvent(source);
  }

  function renderNavBar() {
    return (
      <header className="main-navbar">
        <nav className="navbar-items-container">
          <div className="social-icons-container">
            <div className="navbar-item">{renderWhatsappIcon()}</div>
            <div className="navbar-item">{renderInstagramIcon()}</div>
          </div>
          <div
            className={`navbar-item center-bold contact-me ${
              animate ? "animate" : ""
            }`}
            onClick={scrollToContactUs}
          >
            <button className="submit-button">צרו איתי קשר</button>
          </div>
        </nav>
      </header>
    );
  }

  function renderInstagramIcon() {
    return (
      <a href={INSTAGRAM_PROFILE} target="_blank" rel="noopener noreferrer">
        <img className="instagram-icon" src={instagramIcon} alt="insta-icon" />
      </a>
    );
  }

  function renderWhatsappIcon() {
    return (
      <a
        href={`https://wa.me/${PHONE_NUMBER}`}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <FaWhatsapp size={20} />
      </a>
    );
  }

  function renderPlayersCards() {
    return (
      <div className="players-cards-container-image parallax-image">
        <div className="players-header">ה - PLAYERS שלנו</div>
        <div className="players-cards-container">
          {players?.map((player) => renderPlayerCard(player))}
        </div>
      </div>
    );
  }

  function renderPlayerCard(player: Player) {
    return (
      <PlayerCard
        key={player.name}
        player={player}
        scrollToContactUs={scrollToContactUs}
      />
    );
  }

  function renderAboutUs() {
    return (
      <div className="about-us-container">
        <h1>מי אנחנו?</h1>
        <div className="about-us-content">{informationData?.about}</div>
      </div>
    );
  }

  function renderHowItISWorking() {
    return (
      <div className="about-us-container">
        <h1>איך זה עובד?</h1>
        <div className="about-us-content">{informationData?.whatIsNext}</div>
      </div>
    );
  }

  return (
    <div className="main-container">
      {renderNavBar()}
      <ParallaxVideo scrollToContactUs={scrollToContactUs} />
      <div className="main-container-below-video">
        <div className="contact-me-on-main-page"></div>
        <div className="information-container">
          {renderAboutUs()}
          {renderHowItISWorking()}
        </div>
        {renderPlayersCards()}
        <div ref={contactUsRef}>
          <ContactUs source={source} />
        </div>
      </div>
    </div>
  );
};
export default MainPage;
